const token =
  'eyJraWQiOiJTbHhaN2hxeThlRzciLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJTbHhaN2hxeThlRzdcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.lkb7nSTggG6g73U7RbYn_db6odxvy2R8clke7929NYCTVBX97MPv9Tsyk1xJOapVX5QsQ2C40RqPDS7MyF8JYA';

function createAudioConfig({
  isAutoPlay,
  isScrollEnabled,
  mediaId,
  playOutsideViewport,
}) {
  return {
    token,
    src: `https://lw-amedia-cf.lwcdn.com/v-${mediaId}.mp4`,
    type: 'audio/mp4',
    float_on_scroll: isScrollEnabled ? true : false,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    ui: 'USE_THIN_CONTROLBAR',
    poster: null,
    autoplay: isAutoPlay,
  };
}

function createVideoConfig({
  isAutoPlay,
  isScrollEnabled,
  isMuted,
  playOutsideViewport,
  uiOverrides,
  startQuality,
  videoTitle,
  playerId,
  startTime,
  mediaId,
  logo,
  loop,
}) {
  return {
    token,
    autopause: isScrollEnabled ? false : !playOutsideViewport,
    preload: 'auto',
    float_on_scroll: isScrollEnabled ? true : false,
    muted: isMuted,
    skip_controls: true, // for playlist plugin
    src: `${playerId}/${mediaId}`,
    lang: 'nb',
    ...(loop !== undefined && { loop }),
    ...(startTime !== undefined && { start_time: startTime }),
    ...(uiOverrides && { ui: uiOverrides }),
    ...(videoTitle && { title: videoTitle }),
    ...(isAutoPlay !== undefined && { autoplay: isAutoPlay }),
    ...(startQuality && { start_quality: startQuality }),
    ...(![undefined, null].includes(logo) && {
      logo: logo.toString(),
      logo_alt_text: '',
    }),
  };
}

export function getLogConfig(state) {
  const { player, mediaId, playerId, autoplayRequested } = state;
  return {
    autoplay: autoplayRequested,
    title: player.opts.title,
    src: Array.isArray(player.opts.src) ? (player.opts.src[0]?.src ?? '') : '',
    contentId: `flowplayer:${mediaId}`,
    playerId,
    autopause: player.opts?.autopause,
    live: player.playerState['is-live'] ?? false,
    length: (player.opts?.duration ?? 0) * 1000,
    startTime: player.opts?.start_time ?? 0,
    productFeature: 'adplogger_sdk',
  };
}

export function selectPlayerConfig(state) {
  if (state.isAudio) {
    return createAudioConfig(state);
  }

  return createVideoConfig(state);
}

function leastCommonDenominator(width, height) {
  return height == 0 ? width : leastCommonDenominator(height, width % height);
}

function getAspectRatio(width, height) {
  const factor = leastCommonDenominator(width, height);
  const widthRatio = width / factor;
  const heightRatio = height / factor;
  return `${widthRatio}:${heightRatio}`;
}

export function selectVastParameters(state) {
  if (state.ovpConfig === undefined) {
    throw new Error('ovpConfig is undefined');
  }
  const [width, height] = (state.ovpConfig?.ratio?.split(':') || [1, 1]).map(
    Number
  );
  const { duration } = state.ovpConfig.metadata;
  return {
    // "built-in macros" https://docs.flowplayer.com/plugins/ads#built-in-macros
    autoplay: state.isAutoPlay ?? state.ovpConfig.autoplay ?? 0, // 0, 1 or 2 (with override)
    page_url: state.pageUrl,
    domain: new URL(state.pageUrl).hostname,
    media_duration: duration,
    player_height: state.player.parentElement.offsetHeight,
    player_width: state.player.parentElement.offsetWidth,
    video_url: state.ovpConfig.src.find((src) => src.includes('.m3u8')),
    // "platform specific macros" https://docs.flowplayer.com/plugins/ads#flowplayer-platform-specific-m
    media_id: state.ovpConfig.metadata.media_id,
    title: state.videoTitle ?? state.ovpConfig.metadata.title,
    description: state.ovpConfig.metadata.description,
    duration,
    player_id: state.ovpConfig.metadata.player_id,
    category_id: state.ovpConfig.metadata.category_id,
    category_name: state.ovpConfig.metadata.category_name,
    site_id: state.ovpConfig.metadata.site_id,
    sitegroup_id: state.ovpConfig.metadata.sitegroup_id,
    ad_keywords: state.ovpConfig.metadata.ad_keywords,
    // custom targeting
    // TODO: add acpId, tags from video article
    tags: state.ovpConfig.metadata.tags,
    fritekst: (state.ovpConfig.metadata.ad_keywords ?? '').toLowerCase(),
    muted: state.isMuted,
    aspectratio: getAspectRatio(width, height),
    video_length:
      duration <= 45 ? 'short' : duration <= 120 ? 'medium' : 'long',
  };
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackEvent.payload} Payload
 * @param {{adpType: "playing" | "timeupdate" | "pause" | "ended", duration: number }}
 * @returns {Payload}
 */
export function adpLoggerEventDto({ adpType, duration }) {
  if (
    !['playing', 'timeupdate', 'pause', 'ended'].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and duration values`
    );
  }

  return {
    type: 'video',
    adpType,
    duration,
  };
}

/**
 * @typedef {import('@amedia/adplogger-sdk').AdpEvent.PlaybackAdEvent.payload} Payload
 * @param {{type: "ad-started" | "ad-paused" | "ad-resumed" | "ad-completed" | "ad-skipped", detail: {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number} | {vast_ad_id: string, ad_type: string, ad: {duration: number}, remaining: number, progress: number, adProgress: number, adDuration: number, segmentDuration: number, adDuration: number, currentTime: number, adDuration: number, segmentDuration: number, adDuration: number}}
 * @returns {Payload}
 */
export function adpLoggerAdEventDto({
  adpType,
  adType,
  vastAdId,
  duration,
  adDuration,
}) {
  if (
    ![
      'ad-started',
      'ad-paused',
      'ad-resumed',
      'ad-completed',
      'ad-skipped',
    ].includes(adpType) ||
    Number.isNaN(duration) ||
    typeof duration !== 'number'
  ) {
    throw new Error(
      `Could not create payload duration "${duration}" for the event "${adpType}". Check the adpType and detail values!`
    );
  }

  return {
    type: 'video-ad',
    adType,
    adId: vastAdId,
    adpType,
    duration,
    length: adDuration,
  };
}

/**
 * Generates the inline styles for a video player based on the provided aspect ratio, video title, and teaser title flag.
 *
 * @param {Object} options - The options object.
 * @param {string} options.aspectRatio - The aspect ratio of the video player, type AspectRatio.
 * @param {string} options.videoTitle - The title of the video.
 * @param {boolean} options.hasTeaserTitle - Flag indicating if the video has a teaser title.
 * @return {string} The generated inline styles for the video player.
 */

export const getCustomCSSProperties = ({
  aspectRatio,
  videoTitle,
  hasTeaserTitle,
}) => {
  const titleLength = hasTeaserTitle && videoTitle ? videoTitle.length : 0;
  return `${aspectRatio?.toStyle() || ''} ${
    hasTeaserTitle ? `--brick-player-title-length: ${titleLength}` : ''
  }`.trim();
};
