import '@ungap/with-resolvers';
import { selectVastParameters } from '../model/selectors.js';
import createLogger from './logger.js';

const log = createLogger();

export const BAZAAR_TIMEOUT = 2000;

export function prebidInstreamHandler() {
  log.info(
    `prebid instream: (Brick) Waiting max ${BAZAAR_TIMEOUT}ms for prebid instream done`
  );
  if (!document.querySelector('bazaar-config')) {
    return Promise.reject(new Error('Bazaar not defined'));
  }
  return new Promise((resolve, reject) => {
    let settled = false;

    const instreamDonePromise = new Promise((_resolve) => {
      const instreamDone = (event) => {
        window.removeEventListener('prebid:instream_done', instreamDone);
        if (!settled) {
          log.info(
            'prebid instream: (Brick) Received prebid:instream_done',
            event
          );
          _resolve({ adServerTargeting: event.data.adServerTargeting });
        }
        settled = true;
      };
      window.addEventListener('prebid:instream_done', instreamDone);
    });

    const instreamTimeoutPromise = new Promise((_, _reject) => {
      setTimeout(() => {
        if (!settled) {
          log.info(
            `prebid instream: (Brick) Prebid instream timed out (${BAZAAR_TIMEOUT}ms), rendring flowplayer`
          );
          _reject(
            new Error(
              `Timed out (${BAZAAR_TIMEOUT}ms) while waiting for prebid instream`
            )
          );
        }
        settled = true;
      }, BAZAAR_TIMEOUT);
    });

    Promise.race([instreamDonePromise, instreamTimeoutPromise])
      .then(({ adServerTargeting }) => {
        log.info(
          'prebid instream: (Brick) Adding flowplayer prebid targeting',
          adServerTargeting
        );

        // TODO: move parameters to flowplayer instantiation instead
        // to support multiple players / avoid leaking global state
        // HOW-TO: https://docs.flowplayer.com/plugins/ads#custom-macros
        // https://github.com/amedia/brick/issues/3204
        window.__flowplayerAdParameters = adServerTargeting;
        resolve({ adServerTargeting });
      })
      .catch((e) => {
        log.error(e);
        reject(e);
      });
  });
}

async function asyncAdTagFetcher(store) {
  // ES2024 - polyfilled with @ungap/with-resolvers
  const { resolve, reject, promise } = Promise.withResolvers();
  const timer = setTimeout(() => {
    const error = new Error(
      `Failed to connect to bazaar.instream in ${BAZAAR_TIMEOUT}ms`
    );
    console.warn(error);
    reject(error);
  }, BAZAAR_TIMEOUT);
  window.bazaar = window.bazaar || { instream: [] };
  window.bazaar.instream.push(({ loadVast, version }) => {
    if (!/^1\..*/.test(version)) {
      const error = new Error('Unable to run bazaar version:' + version);
      console.warn(error);
      clearTimeout(timer);
      return reject(error);
    }
    loadVast(selectVastParameters(store.getState()))
      .then(resolve)
      .catch(reject)
      .finally(() => clearTimeout(timer));
  });
  return promise;
}

export function getImaConfig(store) {
  return {
    ima: {
      ads: [
        {
          time: 0,
          adTag: () => asyncAdTagFetcher(store),
        },
      ],
    },
  };
}
